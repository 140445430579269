.js-field-error {
    display: none;
}

.js-field-invalid .js-field-error {
    color: red;
    display: block;
    padding-top: 5px;
}

.js-field-invalid select,
.js-field-invalid input {
    border-color: red;
}
