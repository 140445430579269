#search {
    box-shadow: 0 7px 20px #666666;
}

#search .search-form {
    margin: 20px 0;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

#page-title {
    padding-top: 15px;
    padding-bottom: 15px;
}

form label {
    font-weight: bold;
    color: #484848;
}

.dropzone-slim {
    min-height: 100px;
}

.dropzone-slim .dz-message {
    padding: 1rem 1rem;
}

/* cart */

.cart.cart--top {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
}

.cart.cart--top .icon-shopping-cart {
    font-size: 24px;
}

.cart.cart--top .cart__count{
    position: absolute;
    right: 0;
    top: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 10px;
    color: #fff;
    background: #00a046;
}

/* product */

.product.product--card  .product-title {
    font-size: 18px;
}

.product.product--card .add-to-cart {
    font-size: 20px;
    width: 32px;
    height: 32px;
    background: none;
    border: none;
}

.product.product--card .add-to-cart:hover {
    color: #2250fc;
}

.js-rotate {
    animation: 1s linear 0s normal none infinite running loader-rotate;
}


.product .product-rate {
    float: inherit;
}

.product .product-reviews, .product .product-reviews a {
    float: inherit;
}

.option-list {
    display: block;
    list-style: outside none none;
    padding-left: 0;
}

.option-list__item {
    transition: all 0.3s;
    display: inline-block;
    border: 1px solid #d5d5d5;
    padding: 6px 10px;
    border-radius: 5px;
    margin-bottom: 2px;
}

.option-list--grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.option-list--grid .option-list__item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.option-list__item:hover,
.option-list__item--active {
    color: #ffffff !important;
    background-color: #2250fc;
    border-color: #2250fc;
}
.option-list__item--active {
    font-weight: 600;
}

.option-list__item--active:hover {
    background-color: #0333e8;
    border-color: #0333e8;
}

#search {
    height: auto;
}

#page-title .page-title > h1 {
    font-size: 38px;
    line-height: 44px;
}

#page-title .breadcrumb {
    margin-bottom: 10px;
}

.category-slide .category-slide__title-block {
    height: auto;
    bottom: 0;
    left: 0;
    padding: 0 30px 30px;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.category-slide .category-slide__title {
    color: #fff;
}

.category-slide .category-slide__image {
    width: 100%;
}
.category-slide .category-slide__image-block:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.4;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
    z-index: 1;
}

.category-slide:hover .category-slide__image-block:before {
    opacity: 1;
}

.product-sku-wrapper {
    background-color: rgb(255 217 133 / 65%);
    font-size: 1.15em;
    font-weight: 100;
    color: #282828;
    border-radius: 0.375rem;
    display: inline-block;
    line-height: 1;
    padding: 0.35em 0.65em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.top-menu-orders {
    padding: 2px 4px;
    border-radius: 5px;
    background-color: #f7a93475;
}

#shop-cart {
    overflow: inherit;
}

.modal .modal-content .modal-header {
    display: flex;
}

.btn-close {
    background-size: 1rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.top-menu-requests {
    position: relative;
    margin: 0 10px;
}

.top-menu-requests i {
    font-size: 1.6em;
    top: 3px;
    position: relative;
    color: #343434;
}

.top-menu-requests__count {
    position: absolute;
    left: 4px;
    top: -13px;
    color: white;
    font-size: 0.7rem;
}
