@media (max-width: 1120px) {
    .category-slide .category-slide__title {
        font-size: 1.2em;
        line-height: 1.2em;
    }
}

@media (max-width: 992px) {
    #page-title .page-title > h1 {
        font-size: 30px;
        line-height: 38px;
    }
}

@media (max-width: 600px) {
    .option-list--grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .category-slide .category-slide__title {
        font-size: 1em;
        line-height: 1em;
    }
}

@media (max-width: 520px) {
    #page-title .page-title > h1 {
        font-size: 20px;
        line-height: 32px;
    }

    .breadcrumb {
        margin-left: -15px;
        margin-right: -15px;
        background-color: #fafafa;
    }

    .breadcrumb ul .breadcrumb-item,
    .breadcrumb ul li,
    .breadcrumb ol .breadcrumb-item,
    .breadcrumb ol li {
        display: none;
    }

    .breadcrumb .breadcrumb-item:nth-last-child(2) {
        display: flex !important;
        justify-content: center;
    }

    .breadcrumb ul .breadcrumb-item:nth-last-child(2),
    .breadcrumb ul li:nth-last-child(2),
    .breadcrumb ol .breadcrumb-item:nth-last-child(2),
    .breadcrumb ol li:nth-last-child(2) {
        display: flex !important;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .option-list--grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
